<template>
  <ele-table
    :columns="columns"
    :table-data="tableData"
    :total-items="totalItems"
    :is-full="true"
    :actionsType="actionsType"
    :current-page="pageNumber"
    :hideRowSelection="true"
    @emitRefreshData="clearFilterAndRefresh"
    @emitSelectChange="updateSelectChange"
    @emitOnPageChange="updatePageChange"
    @emitShowSizeChange="updateShowSizeChange"
  >
    <a-row :gutter="24">
      <a-col class="gutter-row" :span="4">
        <a-range-picker
          :placeholder="['转账开始时间', '转账结束时间']"
          v-model="SearchDto.DateRange"
        />
      </a-col>
      <a-col class="gutter-row" :span="4">
        <a-input
          v-model.trim="SearchDto.BatchNo"
          placeholder="结算批次号"
          allow-clear
        />
      </a-col>
      <a-col class="gutter-row" :span="4">
        <a-input
          v-model.trim="SearchDto.OrderNo"
          placeholder="订单编号"
          allow-clear
        />
      </a-col>
      <a-col class="gutter-row" :span="4">
        <a-select
          v-model="SearchDto.Used"
          style="width: 100%"
          show-search
          :filter-option="filterOption"
          :options="statusOptions"
          placeholder="状态"
          allowClear
        >
        </a-select>
      </a-col>
      <a-col class="gutter-row" :span="4">
        <a-range-picker
          :placeholder="['开始使用时间', '结束使用时间']"
          v-model="SearchDto.UseDateRange"
        />
      </a-col>
      <a-col :span="6">
        <a-select
          v-model="SearchDto.CompanyId"
          show-search
          :filter-option="filterOption"
          style="width: 100%"
          :placeholder="签约主体"
          allowClear
        >
          <a-select-option
            v-for="item in Companys"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col class="gutter-row" :span="4">
        <a-button
          type="primary"
          @click="searchData"
          v-if="
            isGranted('Pages.DataCenter.SalaryPayNode.BusAppAccountLogQuery')
          "
        >
          {{ l("Search") }}
        </a-button>
        <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        <a-button
          v-if="
            isGranted('Pages.DataCenter.SalaryPayNode.BusAppAccountLogExport')
          "
          @click="exportToExcel()"
        >
          <a-icon type="file-excel" />
          <span>{{ l("Export") }}</span>
        </a-button>
      </a-col>
    </a-row>
  </ele-table>
</template>
  <script>
import { AppComponentBase } from "@/shared/component-base";
import moment from "moment";
import EleTable from "@/components/ele-table";
import { CommonServiceProxy } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";

export default {
  name: "accountlogModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        BatchNo: undefined,
        OrderNo: undefined,
        Used: false,
        DateRange: [],
        UseDateRange: [],
        SkipCount: 0,
        MaxResultCount: 50,
        CompanyId: undefined,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      statusOptions: [
        {
          label: "已使用",
          value: true,
        },
        {
          label: "未使用",
          value: false,
        },
      ],
      Companys: [],
    };
  },
  methods: {
    initColumns() {
      this.columns = [
        {
          title: "结算批次号",
          dataIndex: "batchNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "订单编号",
          dataIndex: "orderNo",
          align: "center",
          ellipsis: true,
        },
        {
          title: "转账金额",
          dataIndex: "amount",
          align: "center",
          ellipsis: true,
        },
        {
          title: "转账时间",
          dataIndex: "recTime",
          align: "center",
          ellipsis: true,
          type: "time",
        },
        {
          title: "使用状态",
          dataIndex: "status",
          align: "center",
          ellipsis: true,
        },
        {
          title: "使用时间",
          dataIndex: "useTime",
          align: "center",
          ellipsis: true,
          type: "time",
        },
        {
          title: "签约主体",
          dataIndex: "organizationUnitName",
          align: "center",
          ellipsis: true,
        },
      ];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    setDefaultDateRange() {
      const now = moment();
      const startDate = now.clone().subtract(7, "days");
      // 保持结束日期为当前日期
      const endDate = now;
      const formatDate = (date) => date.format("YYYY-MM-DD");
      // 将格式化后的日期放入DateRange数组
      this.SearchDto.DateRange = [formatDate(startDate), formatDate(endDate)];
    },
    //获取所有公司
    getAllCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.Companys.splice(0, this.Companys.length);
          if (res && res.data) {
            this.Companys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * 获取分页
     */
    getData() {
      this.spinning = true;
      var parData = { ...this.SearchDto };
      if (parData.DateRange.length > 1) {
        parData.StartTime = moment(parData.DateRange[0]).format("YYYY-MM-DD");
        parData.EndTime = moment(parData.DateRange[1]).format("YYYY-MM-DD");
      }
      if (parData.UseDateRange.length > 1) {
        parData.StartUseTime = moment(parData.UseDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.EndUseTime = moment(parData.UseDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      let options = {
        url:
          this.$apiUrl +
          "/api/services/app/DataCenter/GetBusAppAccountLogPaged",
        method: "GET",
        params: parData,
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      var parData = { ...this.SearchDto };
      if (parData.DateRange.length > 1) {
        parData.StartTime = moment(parData.DateRange[0]).format("YYYY-MM-DD");
        parData.EndTime = moment(parData.DateRange[1]).format("YYYY-MM-DD");
      }
      if (parData.UseDateRange.length > 1) {
        parData.StartUseTime = moment(parData.UseDateRange[0]).format(
          "YYYY-MM-DD"
        );
        parData.EndUseTime = moment(parData.UseDateRange[1]).format(
          "YYYY-MM-DD"
        );
      }
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/DataCenter/ExportBusAppAccountLog",
          params: parData,
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.SearchDto.BatchNo = undefined;
      this.SearchDto.OrderNo = undefined;
      this.SearchDto.Used = false;
      this.SearchDto.DateRange = [];
      this.SearchDto.UseDateRange = [];
      (this.SearchDto.CompanyId = undefined), (this.pageNumber = 1);
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getAllCutomer();
    this.getAllCompany();
    this.setDefaultDateRange();
  },
  mounted() {
    this.getData();
  },
};
</script>
  <style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
.gutter-row {
  padding: 0 0 10px 5px;
}
</style>
  